<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !applications }"
  >
    <div class="page__placeholder" v-if="!user || !applications">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg
              width="73"
              height="73"
              viewBox="0 0 73 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="36.5"
                cy="36"
                r="24"
                stroke="#FFF7E1"
                stroke-width="2"
              />
              <g filter="url(#filter0_d_22_5658)">
                <path
                  d="M16.1604 23C13.5332 26.8866 12 31.5673 12 36.6045C12 50.0778 22.969 61 36.5 61C50.031 61 61 50.0778 61 36.6045C61 34.8685 60.8179 33.1748 60.4716 31.5413"
                  stroke="#FFCC47"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_22_5658"
                  x="5"
                  y="15.9998"
                  width="63"
                  height="52.0002"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_22_5658"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_22_5658"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && applications">
      <div class="content__page">
        <!-- content__page--margintop -->

        <div class="container">
          <div class="registry__table">
            <div class="table__block--block table__block--shadow">
              <div class="table__block--top">
                <div class="table__block--title">Подтверждение регистрации</div>
              </div>

              <div class="table__block--content">
                <div class="table__block--filter">
                  <div class="table__filter--search">
                    <label class="search__label">
                      <input
                        type="text"
                        value=""
                        name="filter_search"
                        v-model="filters.search"
                        placeholder="Поиск по таблице"
                      />
                      <button>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                            stroke="#0E1839"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21.0004 21L16.6504 16.65"
                            stroke="#0E1839"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </label>
                  </div>
                  <div class="table__filter--order">
                    <multiselect
                      v-model="filterCategories"
                      :options="orderList"
                      label="name"
                      :clearOnSelect="false"
                      :clear-on-select="false"
                      placeholder="Статус"
                      :hideSelected="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                      <template slot="noOptions"> Пусто </template>
                      <template slot="noResult"> Результат не найден </template>
                    </multiselect>
                  </div>
                </div>
                <!-- :items="applications.data" -->
                <v-data-table
                  :headers="headers"
                  :items="filteredlist"
                  :loading="loading"
                  :options.sync="options"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50],
                  }"
                >
                  <template slot="applications.data" slot-scope="props">
                    <td>{{ props.id }}</td>
                    <td>{{ props.name }}</td>
                    <td>{{ props.item.date }}</td>
                    <td>{{ props.item.time }}</td>
                    <td>{{ props.item.from }}</td>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div>
                      <a
                        class="tb__table--btn"
                        @click="$modal.show('modal__registration' + item.id)"
                      >
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.875 10.5C0.875 10.5 4.375 3.5 10.5 3.5C16.625 3.5 20.125 10.5 20.125 10.5C20.125 10.5 16.625 17.5 10.5 17.5C4.375 17.5 0.875 10.5 0.875 10.5Z"
                            stroke="#FFCC47"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.5 13.125C11.9497 13.125 13.125 11.9497 13.125 10.5C13.125 9.05025 11.9497 7.875 10.5 7.875C9.05025 7.875 7.875 9.05025 7.875 10.5C7.875 11.9497 9.05025 13.125 10.5 13.125Z"
                            stroke="#FFCC47"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                    <modal
                      class="modal__block modal__block--middle modal__block--registration"
                      :name="'modal__registration' + item.id"
                      :width="680"
                      :min-width="680"
                      height="auto"
                    >
                      <div class="modal__block--wrapper">
                        <div
                          class="modal__block--close"
                          @click="$modal.hide('modal__registration' + item.id)"
                        >
                          <svg
                            width="42"
                            height="42"
                            viewBox="0 0 42 42"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M31.5 10.5L10.5 31.5"
                              stroke="#0E1839"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.5 10.5L31.5 31.5"
                              stroke="#0E1839"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="modal__wrapper">
                          <div class="modal__title">Данные пользователя</div>
                          <div class="modal__content">
                            <div class="modal__form">
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.name') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.surname') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.organization') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.bin') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled=""
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.adres') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled=""
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.phone') }}
                                  </div>
                                  <TheMask
                                    class="input-linck"
                                    :mask="['+7 (###) #### ## ##']"
                                    disabled=""
                                  ></TheMask>
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.email') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled=""
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>

                              <div
                                class="section--link modal__wrapper--footer modal__wrapper--center"
                              >
                                <button class="next__stage input__button">
                                  Подтвердить
                                </button>
                                <button
                                  class="next__stage input__button btn--error"
                                >
                                  Отклонить
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </modal>
                  </template>
                  <v-alert slot="no-results" :value="true" color="error">
                    Ваш поиск по запросу "{{ filters.search }}" не дал
                    результатов.
                  </v-alert>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'
import { TheMask } from 'vue-the-mask'

export default {
  components: {
    Multiselect,
    TheMask,
  },
  data() {
    return {
      applications: null,
      user: null,

      orderList: [
        {
          status: 'all',
          name: 'Все',
        },
        {
          status: 'Отправлено',
          name: 'Поступившие',
        },
        {
          status: 'rejected',
          name: 'Отказанные',
        },
        {
          status: 'approved ',
          name: 'Одобренные',
        },
      ],
      Categories: 'all',
      filterCategories: {
        status: 'all',
        name: 'Все',
      },
      filters: {
        search: '',
      },
      page: 0,
      totalPassengers: 0,
      loading: false,
      options: {
        itemsPerPage: 5,
        page: 1,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'ФИО', value: 'name' },
        { text: 'Телефон', value: 'phone', width: '200px' },
        { text: 'Почта', value: 'email' },
        { text: 'Наименование организации', value: 'organization' },
        { text: 'Действие', value: 'action', align: 'center', sortable: false },
      ],
    }
  },
  computed: {
    filteredlist() {
      // return this.itemsList.filter((d) => {

      return this.applications.data.filter((d) => {
        if (
          d.view.name
            .toLowerCase()
            .includes(this.filters.search.toLowerCase()) &&
          (this.filterCategories.status == 'all' ||
            d.status == this.filterCategories.status) &&
          (this.Categories == 'all' || d.category_id == this.Categories)
        ) {
          return true
        }
      })
    },
  },
  methods: {
    categoriesTab(category) {
      this.Categories = category
    },
    filterTab(category) {
      this.filterCategories = category
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api.get('applications').then((response) => {
      // console.log(response);
      if (response.data) {
        this.applications = response.data
      }
    })
  },
}
</script>

<style scoped></style>
